import React, { Component } from "react"
import loadable from '@loadable/component'
import Fade from 'react-reveal/Fade';

class BaseComponent extends Component {
  getModuleName = (moduleName) => {
    let compName = null;
    if (/ContentfulSlider/.test(moduleName)) {
      compName = "Hero/heroslider";
    }
    if (/ContentfulBanner/.test(moduleName)) {
      compName = "GenericBanner/genericbannercontainer";
    }
    if (/ContentfulCardsList/.test(moduleName)) {
      compName = "GenericCardList/genericcardlist";
    }

    if (/ContentfulBiLateralBlock/.test(moduleName)) {
      compName = "BiLateralBlock/biLateralBlock";
    }

    if (/ContentfulTextComponent/.test(moduleName)) {
      compName = "TextComponent/textComponent";
    }

    if (/ContentfulCta/.test(moduleName)) {
      compName = "acbuttonlink";
    }

    if (/ContentfulGeneralComponent/.test(moduleName)) {
      compName = "GeneralComponents/generalcomponentcontainer";
    }

    if (/ContentfulAssetComponent/.test(moduleName)) {
      compName = "AssetComponent/assetComponent";
    }

    if(/ContentfulWidgetEmbed/.test(moduleName)) {
      compName = "WidgetEmbed/widgetEmbed"
    }

    return compName;
  }
  constructor() {
    super();
    this._loadedComponent = null;
    this._sectionHeaders = null;//prepare section headers to be used in banner component
  }

  loadComponents = () => {
    const { pageComponents, DEVICE_VIEW, pageContext } = this.props
    if (pageComponents) {
      try {
        if (!this._sectionHeaders) {
          this._sectionHeaders = [];
          pageComponents.forEach(comp => {
            if (comp.__typename === "ContentfulGeneralComponent"
              && comp.componentType === "Section Header") {
              this._sectionHeaders.push({ id: comp.id, text: comp.title });
            }
          });
        }
        const totalComponents = pageComponents.length;
        this._loadedComponent = pageComponents.map((config, index) => {
          let moduleName = this.getModuleName(config.__typename);
          if (moduleName) {
            try {
              config.moduleName = moduleName;
              config.DEVICE_VIEW = DEVICE_VIEW;
              if (pageContext) {
                config.currentPage = pageContext.page;
                if (pageContext.siteSettings
                  && config.__typename === "ContentfulGeneralComponent")
                  config.siteContacts = pageContext.siteSettings.siteContacts;
                if (config.__typename === "ContentfulCta")
                  config.addWrapper = true;
              }

              const ModuleComponent = loadable(() => import(`../${moduleName}`));
              const isFadeComp = (config.__typename === "ContentfulBanner"
                || config.__typename === "ContentfulCardsList"
                || config.__typename === "ContentfulGeneralComponent"
                || config.__typename === "ContentfulWidgetEmbed"
                || index < 2);
              const compClass = `${config.__typename} ${totalComponents == index + 1 ? 'last-component' : ''}`
              if (isFadeComp) {
                if (this._sectionHeaders)
                  config.sectionHeaders = this._sectionHeaders;
                return <div key={index} className={compClass}><ModuleComponent {...config} /></div>
              }
              else
                return <Fade ssrFadeout key={index}><div className={compClass}><ModuleComponent  {...config} /></div></Fade>
            }
            catch (e) {
              console.log(e);
              return null;
            }
          }
          return null;
        });
      }
      catch (e) {
        console.log(e);
      }
    }
    return <div className={this._sectionHeaders != null && this._sectionHeaders.length > 0 ? "section-headers-page" : ""}> {this._loadedComponent}</div>;
  }

  render() {


    return (
      <div className="main-wrapper">
        {
          this.loadComponents()
        }
      </div>
    )
  }
}
export default BaseComponent

