import React, { Component } from "react";
import BaseComponent from "../components/Base";
import { graphql } from "gatsby";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";

class pageTemplate extends Component {
  constructor() {
    super();
    this.state = {
      DEVICE_VIEW: {
        IsMobile: false,
        IsTablet: false,
        IsDesktop: true,
      },
    };
  }
  resize() {
    this.setState({
      DEVICE_VIEW: {
        IsMobile: window.innerWidth <= 719,
        IsTablet: window.innerWidth >= 720 && window.innerWidth <= 967,
        IsDesktop: window.innerWidth >= 968,
      },
    });
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.DEVICE_VIEW.IsMobile === nextState.DEVICE_VIEW.IsMobile &&
      this.state.DEVICE_VIEW.IsDesktop === nextState.DEVICE_VIEW.IsDesktop &&
      this.state.DEVICE_VIEW.IsTablet === nextState.DEVICE_VIEW.IsTablet
    )
      return false;
    return true;
  }
  getCorporationSchema() {
    try {
      const corporationSchema = JSON.parse(
        this.props.pageContext.siteSettings.corpSEO.corporationSchema
      );
      const pageSEOSchema = this.props.pageContext.page.pageSEO;
      if (pageSEOSchema === null) {
        return JSON.stringify(corporationSchema);
      }
      return JSON.stringify({
        ...corporationSchema,
        ...JSON.parse(pageSEOSchema.seoSchema),
      });
    } catch (e) {
      console.log(e);
      return "";
    }
  }

  render() {
    const { DEVICE_VIEW } = this.state;
    const corporationSchema = this.getCorporationSchema();
    const websiteSchema = this.props.pageContext.siteSettings.websiteSEO
      .websiteSchema;
    const pageSchemaObj = JSON.parse(corporationSchema);
    return (
      <Layout {...this.props.pageContext} DEVICE_VIEW={DEVICE_VIEW}>
        <SEO
          title={
            this.props.pageContext.page.pageSEO
              ? pageSchemaObj.name
              : this.props.pageContext.page.title
          }
          description={pageSchemaObj.description}
          corporationSchema={corporationSchema}
          image={pageSchemaObj.logo}
          siteUrl={pageSchemaObj.url}
          pathname={this.props.pageContext.page.slug}
          websiteSchema={websiteSchema}
        />
        {this.props.data &&
          this.props.data.allContentfulPage &&
          this.props.data.allContentfulPage.nodes &&
          this.props.data.allContentfulPage.nodes.length > 0 && (
            <BaseComponent
              pageContext={this.props.pageContext}
              pageComponents={
                this.props.data.allContentfulPage.nodes[0].components
              }
              DEVICE_VIEW={DEVICE_VIEW}
            />
          )}
      </Layout>
    );
  }
}

export const query = graphql`
  query pageContentQuery($slug: String) {
    allContentfulPage(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        slug
        components {
          __typename
          ... on Node {
            ... on ContentfulBanner {
              ...Banner
            }
            ... on ContentfulBiLateralBlock {
              ...BiLateralBlock
            }
            ... on ContentfulSlider {
              slides {
                ...Banner
              }
            }
            ... on ContentfulCardsList {
              ...CardsList
            }

            ... on ContentfulTextComponent {
              ...TextComponent
            }

            ... on ContentfulGeneralComponent {
              ...GeneralComponent
            }

            ... on ContentfulCta {
              ...Cta
            }
            ... on ContentfulAssetComponent {
              ...AssetComponent
            }
            ... on ContentfulWidgetEmbed {
              ...WidgetEmbed
            }
          }
        }
      }
    }
  }

  fragment BiLateralBlock on ContentfulBiLateralBlock {
    eyebrow
    bilateralTitle: title {
      title
    }
    bilateralDescription: description {
      json
    }
    cta {
      ...Cta
    }
    resourceHeader
    resourceList {
      ...Cta
    }
    showBadge
    displayImageTop
    style
    image {
      file {
        url
      }
    }
  }

  fragment CardsList on ContentfulCardsList {
    title
    theme
    displayAsGallerySlider
    showFilterMenu
    allFilterText
    categoryOrder {
      id
      category
      patternType
      image {
        file {
          url
        }
      }
      headline: category
      __typename
      navigateToPage {
        title
        slug
      }
    }
    cards {
      id
      cardType
      headline
      image {
        file {
          url
        }
      }
      patternType
      description {
        description
      }
      categories {
        category
      }
      eyebrow
      url
      navigateToPage {
        slug
        components {
          __typename
          ... on Node {
            ... on ContentfulBanner {
              title
            }
            ... on ContentfulBiLateralBlock {
              bilateralTitle: title {
                title
              }
              cta {
                ...Cta
              }
              resourceHeader
              resourceList {
                ...Cta
              }
            }
          }
        }
      }
    }
  }
  fragment Banner on ContentfulBanner {
    id
    eyebrow
    title
    description {
      json
    }
    theme
    bannerStyle
    targetAudience
    image {
      file {
        url
      }
    }
    mobileImage {
      file {
        url
      }
    }
    badge {
      file {
        url
      }
    }
    ctaButton {
      ...Cta
    }
    isOverlapAllowed
    patternType
  }
  fragment Cta on ContentfulCta {
    text
    navigateTo {
      file {
        url
      }
    }
    url
    style
    theme
    openInNewWindow
    icon {
      file {
        url
      }
    }
  }

  fragment TextComponent on ContentfulTextComponent {
    title
    description {
      json
    }
    columnLayout
    ctaLinks {
      ...Cta
    }
  }

  fragment GeneralComponent on ContentfulGeneralComponent {
    id
    title
    componentType
    description {
      json
    }
  }

  fragment AssetComponent on ContentfulAssetComponent {
    video
    category
    assetDescription
    image {
      file {
        url
      }
    }
  }

  fragment WidgetEmbed on ContentfulWidgetEmbed {
    title
    script
  }
`;

export default pageTemplate;
